<template>
  <div class="download">
    <div class="downStyle" v-if="JSON.stringify(item) != '{}'">
      <div class="cardLeft">
        <img src="@/assets/projectTypeIcon/type_zhuan.png" alt="" class="typeIcon" v-if="item.alias && item.alias=='专'">
        <div v-else>
          <img src="@/assets/projectTypeIcon/type_ri.png" alt="" class="typeIcon" v-if="item.deliverType=='日报'">
          <img src="@/assets/projectTypeIcon/type_zhou.png" alt="" class="typeIcon" v-else-if="item.deliverType=='周报'">
          <img src="@/assets/projectTypeIcon/type_yue.png" alt="" class="typeIcon" v-else-if="item.deliverType=='月报'">
          <img src="@/assets/projectTypeIcon/type_ji.png" alt="" class="typeIcon" v-else-if="item.deliverType=='季报'">
          <img src="@/assets/projectTypeIcon/type_nian.png" alt="" class="typeIcon" v-else-if="item.deliverType=='年报' || item.deliverType=='半年报'">
          <img src="@/assets/projectTypeIcon/type_lin.png" alt="" class="typeIcon" v-else>
        </div>
        <div class="cardContent">
          <div class="projectName">{{ item.projectName }}</div>
          <!-- <div class="projectTime">{{ item.deliverTime }}</div> -->
        </div>
        <div class="cardBtn" :class="item.deliverType=='日报'?'cardBtnRi':item.deliverType=='周报'?'cardBtnZhou':item.deliverType=='月报'?'cardBtnYue':item.deliverType=='季报'?'cardBtnJi':(item.deliverType=='年报' || item.deliverType=='半年报')?'cardBtnNian':item.deliverType=='专题报告'?'cardBtnZhuan':'cardBtnLin'">
          <div class="downBtn" v-if="downloading">下载中，请稍后</div>
          <div class="downBtn" v-else @click="goDownload()">一键下载</div>
          <div class="downBtn twoBtn" @click="lookAllDown()">查看报告</div>
          <div class="downBtn" style="margin-left: 10px;" @click="confirmReport()" v-if="item.customerConfirmationStatus==1">确认报告</div>
        </div>
      </div>
      <div class="cardRight">
        <img src="@/assets/projectTypeIcon/project_zhuan.png" alt="" class="projectImg" v-if="item.alias && item.alias=='专'">
        <div v-else>
          <img src="@/assets/projectTypeIcon/project_ri.png" alt="" class="projectImg" v-if="item.deliverType=='日报'">
          <img src="@/assets/projectTypeIcon/project_zhou.png" alt="" class="projectImg" v-else-if="item.deliverType=='周报'">
          <img src="@/assets/projectTypeIcon/project_yue.png" alt="" class="projectImg" v-else-if="item.deliverType=='月报'">
          <img src="@/assets/projectTypeIcon/project_ji.png" alt="" class="projectImg" v-else-if="item.deliverType=='季报'">
          <img src="@/assets/projectTypeIcon/project_nian.png" alt="" class="projectImg" v-else-if="item.deliverType=='年报' || item.deliverType=='半年报'">
          <img src="@/assets/projectTypeIcon/project_lin.png" alt="" class="projectImg" v-else>
        </div>
      </div>
    </div>
    <div class="noLogin" v-if="!isLogin">点击<span class="loginStyle" @click="goLogin()">登录</span>，查看更多</div>
    <Modal v-model="downModal" width="700" :footer-hide="true">
      <div class="mainDown">
        <div class="downHeader">
          <img src="@/assets/projectTypeIcon/type_zhuan.png" alt="" class="downImg" v-if="item.alias && item.alias=='专'">
          <div v-else>
            <img src="@/assets/projectTypeIcon/type_ri.png" alt="" class="downImg" v-if="item.deliverType=='日报'">
            <img src="@/assets/projectTypeIcon/type_zhou.png" alt="" class="downImg" v-else-if="item.deliverType=='周报'">
            <img src="@/assets/projectTypeIcon/type_yue.png" alt="" class="downImg" v-else-if="item.deliverType=='月报'">
            <img src="@/assets/projectTypeIcon/type_ji.png" alt="" class="downImg" v-else-if="item.deliverType=='季报'">
            <img src="@/assets/projectTypeIcon/type_nian.png" alt="" class="downImg" v-else-if="item.deliverType=='年报' || item.deliverType=='半年报'">
            <img src="@/assets/projectTypeIcon/type_lin.png" alt="" class="downImg" v-else>
          </div>
          <div class="headerRight">
            <div class="headerRightName">{{ item.projectName }}</div>
            <!-- <div class="headerRightTime">{{ item.deliverTime }}</div> -->
          </div>
        </div>
        <div class="downContent" v-if="allDownList.length!=0">
          <div class="downRow" v-for="(item,index) in allDownList" :key="index">
            <div class="downBtnTxtMain" @click="changSelDown(item)">
              <Checkbox v-model="item.selDown">
                <span class="downBtnTxt">{{ item.fileName }}</span>
              </Checkbox>
              <div class="texModal"></div>
            </div>
            <div class="downBtnModal" @click="goDown(true,item.fileName)">下载</div>
          </div>
        </div>
        <div class="downContent" v-else>
          <div class="noDataTxt">暂无数据</div>
        </div>
        <div class="downFoot">
          <div class="btn" @click="downModal = false;">关闭</div>
          <div class="btn downBtn" @click="goDown()">批量下载</div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getDownLoadList } from '@/apis/download'
import { downloadFirl, getDownloadFirlList } from '@/apis/deliver'
export default {
  data() {
    return {
      item: {},
      deliverId: '',
      projectId: '',
      downloading: false,
      isLogin: false,
      downModal: false,
      downData: {},
      selDown: false,
      allDownList: [],
    }
  },
  mounted() {
    // 获取路由参数
    this.deliverId = this.$route.params.deliverId
    this.projectId = this.$route.params.projectId
    this.isLogin = this.$route.query.isLogin
    if (this.deliverId && this.projectId) {
      this.getData()
    }
  },
  methods: {
    // 获取下载列表
    async getData() {
      let { body } = await getDownLoadList({
        deliverId: this.deliverId,
        projectId: this.projectId,
      })
      if (body) {
        this.item = body
      } else {
        this.$Message.error('获取下载列表失败')
      }
    },
    goDownload() {
      if (!this.isLogin) {
        this.$Message.error('请先登录')
        window.location.href =
          process.env.VUE_APP_JUMP_URL +
          '/#/login' +
          '?deliverId=' +
          this.deliverId +
          '&projectId=' +
          this.projectId
      } else {
        this.goDownAllList()
      }
    },
    //下载报告
    async goDownAllList() {
      this.downloading = true
      let { body } = await downloadFirl({
        deliverId: this.deliverId,
        projectId: this.projectId,
      })
      if (body) {
        this.downloading = false
        window.location.href = process.env.VUE_APP_BASE_URL + body
        this.$track(
          '报告中心',
          '下载报告',
          `下载项目${this.item.projectName}，交付频次${this.item.deliverType}，交付期数${this.item.deliverNumber}，交付时间${this.item.deliverTime}的报告。`
        )
      } else {
        this.downloading = false
        this.$Message.error('下载失败')
      }
    },
    // 跳转星图云登录
    goLogin() {
      window.location.href =
        process.env.VUE_APP_JUMP_URL +
        '/#/login' +
        '?deliverId=' +
        this.deliverId +
        '&projectId=' +
        this.projectId
    },
    //确认报告
    async confirmReport() {
      const { code, message } = await confirmReportData({
        deliverId: this.deliverId,
      })
      if (code && code == 200) {
        this.getData()
        this.$Message.success(message)
      } else {
        this.$Message.error(message)
      }
    },
    //查看所有的下载
    async lookAllDown(){
      let { body } = await getDownloadFirlList({ deliverId: this.deliverId });
      if(body&&body.length!=0){
        body.forEach((item)=>{
          item.selDown = false;
        });
        this.allDownList = body;
        this.downModal = true;
      }else{
        this.allDownList = [];
        this.downModal = true;
      };
    },
    //弹窗的下载报告
    goDown(params,fileName) {
      //单独下载
      if(params){
        //把 & 转化成 %26
        fileName=fileName.replace(/&/g, "%26");
        // //把 ? 转化成 %3F
        fileName=fileName.replace(/\?/g, "%3F");
        // //把 / 转化成 %2F
        fileName=fileName.replace(/\//g, "%2F");
        window.location.href = `${process.env.VUE_APP_BASE_URL}/download/zipDownload?deliverId=${this.deliverId}&projectId=${this.projectId}&fileName=${fileName}`;
        // window.open(`${process.env.VUE_APP_BASE_URL}/download/zipDownload?deliverId=${this.deliverId}&projectId=${this.projectId}&fileName=${fileName}`);
        return;
      };
      //批量下载
      let selArr = this.allDownList.filter((item)=>{ return item.selDown; });
      if(selArr.length==0){
        this.$Message.error('请选择需要下载的文件');
        return;
      };
      this.allDownList.forEach((item,index)=>{
        if(item.selDown){
          setTimeout(()=>{
            //把 & 转化成 %26
            item.fileName=item.fileName.replace(/&/g, "%26");
            // //把 ? 转化成 %3F
            item.fileName=item.fileName.replace(/\?/g, "%3F");
            // //把 / 转化成 %2F
            item.fileName=item.fileName.replace(/\//g, "%2F");
            window.location.href = `${process.env.VUE_APP_BASE_URL}/download/zipDownload?deliverId=${this.deliverId}&projectId=${this.projectId}&fileName=${item.fileName}`;
          },200*index);
          // window.open(`${process.env.VUE_APP_BASE_URL}/download/zipDownload?deliverId=${this.deliverId}&projectId=${this.projectId}&fileName=${item.fileName}`);
        }
      });
    },
    //选择
    changSelDown(item){
      let selArr = this.allDownList.filter((item)=>{ return item.selDown; });
      if(selArr.length == 6 && !item.selDown){
        this.$Message.error('最多选择6个文件');
        return;
      };
      item.selDown = !item.selDown;
    },
  },
}
</script>

<style scoped lang="scss">
.download {
  width: 100vw;
  height: calc(100vh - 70px);
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .downStyle {
    width: 488px;
    height: 275px;
    background: #ffffff;
    box-shadow: 0px 12px 60px 0px rgba(0, 0, 0, 0.06);
    border-radius: 36px;
    padding: 60px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .cardLeft {
      width: 240px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .typeIcon {
        width: 27px;
        height: 27px;
      }

      .cardContent {
        width: 115%;
        height: 80px;
        // margin-top: 5px;
        .projectName {
          width: 100%;
          font-size: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #333333;
          //超出两行显示省略号
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          -o-text-overflow: ellipsis;
        }

        .projectTime {
          width: 100%;
          font-size: 21px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
        }
      }

      .cardBtn {
        width: 150%;
        display: flex;
        z-index: 1000;
        .downBtn {
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FC773C;
          cursor: pointer;
        }
        .twoBtn {
          margin-left: 10px;
        }
      }
      .cardBtnRi{
          .downBtn{
            color: #CD72FB;
          }
        }
        .cardBtnZhou{
          .downBtn{
            color: #7986FC;
          }
        }
        .cardBtnYue{
          .downBtn {
            color: #FF9900;
          }
        }
        .cardBtnJi{
          .downBtn {
            color: #48DBFE;
          }
        }
        .cardBtnNian{
          .downBtn {
            color: #F6B175;
          }
        }
        .cardBtnLin{
          .downBtn {
            color: #FC773C;
          }
        }
        .cardBtnZhuan{
          .downBtn {
            color: #FB7272;
          }
        }
    }

    .cardRight {
      .projectImg {
        width: 200px;
        height: 170px;
      }
    }
  }

  .noLogin {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    margin-top: 40px;

    .loginStyle {
      color: #328cff;
      cursor: pointer;
    }
  }
}
//弹窗样式
.mainDown{
  .downHeader{
    padding: 30px 30px 0;
    display: flex;
    align-items: center;
    .downImg{
      width: 40px;
      height: 40px;
    }
    .headerRight{
      width: 100%;
      font-size: 16px;
      font-family: "SourceHanSansCN-Medium, SourceHanSansCN";
      font-weight: 500;
      color: #333333;
      margin-left: 20px;
      .headerRightName{
        width: 320px;
        color: #333333;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .headerRightTime{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .downContent{
    width: 100%;
    min-height: 100px;
    margin: 0px 0 30px;
    max-height: 384px;
    overflow-y: scroll;
    padding: 30px;
    .downRow{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .downBtnModal{
        height: 20px;
        font-size: 14px;
        font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
        font-weight: 400;
        color: #FF9900;
        cursor: pointer;
        //hover下划线
        &:hover{
          border-bottom: 1px solid #FF9900;
        }
      }
    }
    .noDataTxt{
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
  }
  .downFoot{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0 30px;
    .btn{
      width: 80px;
      height: 30px;
      border-radius: 15px;
      border: 1px solid #FF9900;
      font-size: 12px;
      font-family: "SourceHanSansCN-Regular, SourceHanSansCN";
      font-weight: 400;
      color: #FF9900;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      margin: 0 10px;
    }
    .downBtn{
      background: #FF9900;
      color: #fff;
      //hover
      &:hover{
        background: #ff8800;
        border: 1px solid #ff8800;
      }
    }
  }
}
.downBtnTxtMain{
  position: relative;
  .texModal{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
}
::v-deep .ivu-checkbox-default{
  display: flex;
  align-items: center;
  .downBtnTxt{
    width: 480px;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    margin-left: 5px;
  }
}
::v-deep .ivu-modal-body{
  padding: 0;
}
//弹窗多选框样式
::v-deep .ivu-checkbox-checked .ivu-checkbox-inner{
  background-color: rgba(0,0,0,0);
  border-color: #FF9900;
}
::v-deep .ivu-checkbox-checked .ivu-checkbox-inner:after{
  border-color: #FF9900;
}
::v-deep .ivu-checkbox-checked{
  margin-top: 3px;
}
::v-deep .ivu-checkbox{
  margin-top: 3px;
}
</style>