<template>
  <div class="contentMain">
    <div class="searchDiv">
      <div class="lableStyle">
        <div class="searchLable">项目名称</div>
        <Select v-model="projectId" style="width:200px" placeholder="请选择项目名称" class="selStyle" @on-change="getDeliveryTypeData()">
          <Option v-for="(item,index) in projectNameList" :value="item.projectId" :key="index">{{ item.projectName }}
          </Option>
        </Select>
      </div>
      <div class="lableStyle">
        <div class="searchLable searchStatus">项目时间</div>
        <!-- <DatePicker :value="timeList" format="yyyy/MM" type="daterange" placement="bottom-end" placeholder="请选择交付时间" style="width: 200px" @on-change="changeTime" clearable ></DatePicker> -->
        <!-- <el-date-picker v-model="timeList" type="monthrange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small" format="yyyy-MM" value-format="yyyy-MM"
          :unlink-panels="true" style="width: 200px;" @change="changeTime"></el-date-picker> -->
        <DatePicker v-model="searchTime" type="month" format="yyyy-MM" placeholder="请选择项目时间" style="width: 200px" clearable @on-change="changeTime"></DatePicker>
      </div>
      <div class="lableStyle">
        <div class="searchLable searchStatus">报告类型</div>
        <Select v-model="deliveryType" style="width:200px" placeholder="请选择报告类型" class="selStyle" @on-change="changeSel()" clearable>
          <Option v-for="(item,index) in deliveryTypeList" :value="item.deliverType" :key="index">{{ item.deliverType }}
          </Option>
        </Select>
      </div>
      <div class="lableStyle">
        <div class="searchLable searchStatus">下载状态</div>
        <Select v-model="isDownload" style="width:200px" placeholder="请选择下载状态" class="selStyle" @on-change="changeSel()" clearable>
          <Option value="0">未下载</Option>
          <Option value="1">已下载</Option>
        </Select>
      </div>
    </div>
    <div class="main">
      <div ref="scrollContainer" @scroll="handleScroll" class="divStyle" v-if="projectList.length!=0">
        <div class="cardDiv" v-for="(item,index) in projectList" :key="index">
          <div class="cardLeft">
            <img src="@/assets/projectTypeIcon/type_zhuan.png" alt="" class="typeIcon" v-if="item.alias && item.alias=='专'">
            <div v-else>
              <img src="@/assets/projectTypeIcon/type_ri.png" alt="" class="typeIcon" v-if="item.deliverType=='日报'">
              <img src="@/assets/projectTypeIcon/type_zhou.png" alt="" class="typeIcon" v-else-if="item.deliverType=='周报'">
              <img src="@/assets/projectTypeIcon/type_yue.png" alt="" class="typeIcon" v-else-if="item.deliverType=='月报'">
              <img src="@/assets/projectTypeIcon/type_ji.png" alt="" class="typeIcon" v-else-if="item.deliverType=='季报'">
              <img src="@/assets/projectTypeIcon/type_nian.png" alt="" class="typeIcon" v-else-if="item.deliverType=='年报' || item.deliverType=='半年报'">
              <img src="@/assets/projectTypeIcon/type_lin.png" alt="" class="typeIcon" v-else>
            </div>
            <div class="cardContent">
              <div class="projectName">{{ item.numberOfPeriods }}</div>
              <!-- <div class="projectTime">{{ item.deliverTime }}</div> -->
            </div>
            <div class="cardBtn"
              :class="item.deliverType=='日报'?'cardBtnRi':item.deliverType=='周报'?'cardBtnZhou':item.deliverType=='月报'?'cardBtnYue':item.deliverType=='季报'?'cardBtnJi':(item.deliverType=='年报' || item.deliverType=='半年报')?'cardBtnNian':item.deliverType=='专题报告'?'cardBtnZhuan':'cardBtnLin'">
              <div>
                <div class="downBtn" v-if="item.downloading">下载中，请稍后</div>
                <div class="downBtn" @click="goDownOneAll(item)" v-else>一键下载</div>
              </div>
              <div class="downBtn twoBtn" @click="lookAllDown(item)">查看报告</div>
              <div class="downBtn twoBtn" v-if="item.customerConfirmationStatus==1" @click="confirmReport(item)">确认报告</div>
            </div>
          </div>
          <div class="cardRight">
            <img src="@/assets/projectTypeIcon/project_zhuan.png" alt="" class="projectImg" v-if="item.alias && item.alias=='专'">
            <div v-else>
              <img src="@/assets/projectTypeIcon/project_ri.png" alt="" class="projectImg" v-if="item.deliverType=='日报'">
              <img src="@/assets/projectTypeIcon/project_zhou.png" alt="" class="projectImg" v-else-if="item.deliverType=='周报'">
              <img src="@/assets/projectTypeIcon/project_yue.png" alt="" class="projectImg" v-else-if="item.deliverType=='月报'">
              <img src="@/assets/projectTypeIcon/project_ji.png" alt="" class="projectImg" v-else-if="item.deliverType=='季报'">
              <img src="@/assets/projectTypeIcon/project_nian.png" alt="" class="projectImg" v-else-if="item.deliverType=='年报' || item.deliverType=='半年报'">
              <img src="@/assets/projectTypeIcon/project_lin.png" alt="" class="projectImg" v-else>
            </div>
          </div>
          <div class="confirmDiv" :class="item.isDownload == 0 ? '' : 'overDown'">
            {{ item.isDownload == 0 ? '未下载' : '已下载' }}
          </div>
        </div>
        <div class="getOver" v-if="getOver && projectList.length!=0">已加载全部</div>
      </div>
      <div class="noData" v-else>暂无数据</div>
    </div>
    <Modal v-model="downModal" width="700" :footer-hide="true">
      <div class="mainDown">
        <div class="downHeader">
          <img src="@/assets/projectTypeIcon/type_zhuan.png" alt="" class="downImg" v-if="downData.alias && downData.alias=='专'">
          <div v-else>
            <img src="@/assets/projectTypeIcon/type_ri.png" alt="" class="downImg" v-if="downData.deliverType=='日报'">
            <img src="@/assets/projectTypeIcon/type_zhou.png" alt="" class="downImg" v-else-if="downData.deliverType=='周报'">
            <img src="@/assets/projectTypeIcon/type_yue.png" alt="" class="downImg" v-else-if="downData.deliverType=='月报'">
            <img src="@/assets/projectTypeIcon/type_ji.png" alt="" class="downImg" v-else-if="downData.deliverType=='季报'">
            <img src="@/assets/projectTypeIcon/type_nian.png" alt="" class="downImg" v-else-if="downData.deliverType=='年报' || downData.deliverType=='半年报'">
            <img src="@/assets/projectTypeIcon/type_lin.png" alt="" class="downImg" v-else>
          </div>
          <div class="headerRight">
            <div class="headerRightName">{{ downData.numberOfPeriods }}</div>
            <!-- <div class="headerRightTime">{{ downData.deliverTime }}</div> -->
          </div>
        </div>
        <div class="downContent" v-if="allDownList.length!=0">
          <div class="downRow" v-for="(item,index) in allDownList" :key="index">
            <div class="downBtnTxtMain" @click="changSelDown(item)">
              <Checkbox v-model="item.selDown">
                <span class="downBtnTxt">{{ item.fileName }}</span>
              </Checkbox>
              <div class="texModal"></div>
            </div>
            <div class="downBtnModal" @click="goDown(downData,item.fileName)">下载</div>
          </div>
        </div>
        <div class="downContent" v-else>
          <div class="noDataTxt">暂无数据</div>
        </div>
        <div class="downFoot">
          <div class="btn" @click="downModal = false;">关闭</div>
          <div class="btn downBtn" @click="goDown()">批量下载</div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getDeliveryCenterList,
  getDeliveryTypeList,
  confirmReportData,
  downloadFirl,
  getDownloadFirlList,
} from '@/apis/deliver'
import { getAllProjectList } from '@/apis/project'
export default {
  data() {
    return {
      projectId: '',
      deliveryType: '',
      isDownload: '',
      timeList: [],
      searchTime: '',
      projectNameList: [], //所有的项目名称列表
      deliveryTypeList: [], //所有的交付类型列表
      projectList: [],
      pages: 1,
      total: 0,
      getOver: false,
      downModal: false,
      downData: {},
      selDown: false,
      allDownList: [],
    }
  },
  mounted() {
    this.$track('交付中心', '查看交付中心', `登录进入交付中心页。`)
    //获取router传递的参数
    this.projectId = this.$route.query.projectId
    //获取所有项目名称列表
    this.getAllProjectData()
  },
  methods: {
    //获取项目列表/是否第一次加载/是否需要清空列表
    async getData(init, clear) {
      const { body } = await getDeliveryCenterList({
        pages: this.pages,
        rows: 10,
        projectId: this.projectId,
        deliveryType: this.deliveryType,
        isDownload: this.isDownload,
        startTime: this.searchTime,
      })
      if (body && body.data && body.data.length != 0) {
        if (clear) {
          this.projectList = []
        }
        body.data.forEach((item) => {
          item.downloading = false
          this.projectList.push(item)
        })
        this.total = body.total
        if (this.total <= this.projectList.length) {
          this.getOver = true
        } else {
          this.getOver = false
        }
        if (body.total > 10 && init) {
          this.pages = 2
          this.getData()
        }
      } else {
        // this.projectList = []
        // this.total = 0
      }
    },
    //获取所有项目列表
    async getAllProjectData() {
      const { body } = await getAllProjectList()
      if (body && body.length != 0) {
        this.projectNameList = body
        if (this.projectId == '' || !this.projectId) {
          this.projectId = body[0].projectId
        }
        this.getDeliveryTypeData()
      } else {
        this.projectNameList = []
      }
    },
    //获取交付类型列表
    async getDeliveryTypeData() {
      this.pages = 1
      this.getData(true, true)
      const { body } = await getDeliveryTypeList({ projectId: this.projectId })
      if (body && body.length != 0) {
        this.deliveryTypeList = body
      } else {
        this.deliveryTypeList = []
      }
    },
    //确认报告
    async confirmReport(item) {
      const { code, message } = await confirmReportData({
        deliverId: item.deliverId,
      })
      if (code && code == 200) {
        item.customerConfirmationStatus = 0
        this.$Message.success(message)
        this.$track(
          '交付中心',
          '确认报告',
          `下载项目${item.projectName}，交付频次${item.deliverType}，交付期数${item.numberOfPeriods}，交付时间${item.deliverTime}的报告。`
        )
        // this.pages = 1;
        // this.getData(true,true);
      } else {
        this.$Message.error(message)
      }
    },
    //查看所有的下载
    async lookAllDown(item) {
      this.downData = item
      let { body } = await getDownloadFirlList({ deliverId: item.deliverId })
      if (body && body.length != 0) {
        body.forEach((item) => {
          item.selDown = false
        })
        this.allDownList = body
        this.downModal = true
      } else {
        this.allDownList = []
        this.downModal = true
      }
    },
    //选择
    changSelDown(item) {
      let selArr = this.allDownList.filter((item) => {
        return item.selDown
      })
      if (selArr.length == 6 && !item.selDown) {
        this.$Message.error('最多选择6个文件')
        return
      }
      item.selDown = !item.selDown
    },
    //弹窗的下载报告
    goDown(params, fileName) {
      this.downData.isDownload = 1
      //单独下载
      if (params) {
        //把 & 转化成 %26
        fileName = fileName.replace(/&/g, '%26')
        // //把 ? 转化成 %3F
        fileName = fileName.replace(/\?/g, '%3F')
        // //把 / 转化成 %2F
        fileName = fileName.replace(/\//g, '%2F')
        window.location.href = `${process.env.VUE_APP_BASE_URL}/download/zipDownload?deliverId=${params.deliverId}&projectId=${params.projectId}&fileName=${fileName}`
        // window.open(`${process.env.VUE_APP_BASE_URL}/download/zipDownload?deliverId=${params.deliverId}&projectId=${params.projectId}&fileName=${fileName}`);
        return
      }
      //批量下载
      let selArr = this.allDownList.filter((item) => {
        return item.selDown
      })
      if (selArr.length == 0) {
        this.$Message.error('请选择需要下载的文件')
        return
      }
      this.allDownList.forEach((item, index) => {
        if (item.selDown) {
          setTimeout(() => {
            //把 & 转化成 %26
            item.fileName = item.fileName.replace(/&/g, '%26')
            // //把 ? 转化成 %3F
            item.fileName = item.fileName.replace(/\?/g, '%3F')
            // //把 / 转化成 %2F
            item.fileName = item.fileName.replace(/\//g, '%2F')
            window.location.href = `${process.env.VUE_APP_BASE_URL}/download/zipDownload?deliverId=${this.downData.deliverId}&projectId=${this.downData.projectId}&fileName=${item.fileName}`
          }, 200 * index)
          // window.open(`${process.env.VUE_APP_BASE_URL}/download/zipDownload?deliverId=${this.downData.deliverId}&projectId=${this.downData.projectId}&fileName=${item.fileName}`);
        }
      })
    },
    //一键下载
    async goDownOneAll(item) {
      item.downloading = true
      let { body } = await downloadFirl({
        deliverId: item.deliverId,
        projectId: item.projectId,
      })
      if (body) {
        item.isDownload = 1
        item.downloading = false
        window.location.href = process.env.VUE_APP_BASE_URL + body
        this.$track(
          '报告中心',
          '下载报告',
          `下载项目${item.projectName}，交付频次${item.deliverType}，交付期数${item.deliverNumber}，交付时间${item.deliverTime}的报告。`
        )
      } else {
        item.downloading = false
        this.$Message.error('下载失败')
      }
    },
    //修改时间
    changeTime(time) {
      this.pages = 1
      this.searchTime = time
      this.projectList = []
      this.getData(true, true)
    },
    // 修改赛选内容
    changeSel() {
      this.pages = 1
      this.projectList = []
      this.getData(true, true)
    },
    handleScroll() {
      // 判断是否滚动到底部
      const scrollDistance =
        this.$refs.scrollContainer.scrollHeight -
        this.$refs.scrollContainer.scrollTop -
        this.$refs.scrollContainer.clientHeight
      if (scrollDistance <= 0) {
        // 滚动到底部触发的逻辑
        if (this.total <= this.projectList.length) {
          this.getOver = true
          return
        }
        this.pages++
        this.getData()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.contentMain {
  width: 100vw;
  height: calc(100vh - 70px);
  // padding: 0 100px;

  .searchDiv {
    width: 100vw;
    padding: 0 100px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    height: 150px;

    .searchLable {
      width: 70px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      margin-right: 10px;
    }

    .searchStatus {
      margin-left: 20px;
    }

    .lableStyle {
      display: flex;
      align-items: center;
    }
  }

  .main {
    height: calc(100vh - 220px);

    .cardDiv {
      width: 325px;
      height: 183px;
      background: #ffffff;
      box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.06);
      border-radius: 24px;
      padding: 40px 20px;
      box-sizing: border-box;
      margin: 0 20px 60px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 10;
      //阴影
      &:hover {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
      }
      .cardLeft {
        width: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .typeIcon {
          width: 22px;
          height: 22px;
        }

        .cardContent {
          width: 120%;
          height: 80px;
          margin-top: 5px;
          .projectName {
            width: 100%;
            font-size: 16px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #333333;
            //超出两行显示省略号
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -o-text-overflow: ellipsis;
          }

          .projectTime {
            width: 100%;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
          }
        }

        .cardBtn {
          width: 150%;
          display: flex;
          z-index: 1000;
          .downBtn {
            font-size: 12px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: bold;
            color: #fc773c;
            cursor: pointer;
          }

          .twoBtn {
            margin-left: 10px;
          }
        }
        .cardBtnRi {
          .downBtn {
            color: #cd72fb;
          }
        }
        .cardBtnZhou {
          .downBtn {
            color: #7986fc;
          }
        }
        .cardBtnYue {
          .downBtn {
            color: #ff9900;
          }
        }
        .cardBtnJi {
          .downBtn {
            color: #48dbfe;
          }
        }
        .cardBtnNian {
          .downBtn {
            color: #f6b175;
          }
        }
        .cardBtnLin {
          .downBtn {
            color: #fc773c;
          }
        }
        .cardBtnZhuan {
          .downBtn {
            color: #fb7272;
          }
        }
      }
      .cardRight {
        .projectImg {
          width: 150px;
          height: 120px;
        }
      }
      .confirmDiv {
        width: 50px;
        position: absolute;
        top: 10px;
        right: 0;
        z-index: 10;
        font-size: 12px;
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
        font-weight: 400;
        color: #ff3e3e;
      }
      .overDown {
        color: #999999;
      }
    }

    .getOver {
      width: 100%;
      text-align: center;
      padding: 20px 0;
    }

    .divStyle {
      max-height: calc(100vh - 220px);
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      overflow-y: auto;
      padding: 40px 100px 0;
      margin-top: -40px;
    }

    .noData {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .scrollStyle {
    height: 100%;
  }
}

::v-deep .selStyle .ivu-select-selection {
  border: none;
  color: #333333;
}

::v-deep .selStyle .ivu-select-arrow {
  color: #bfc3d6;
}

::v-deep .selStyle .ivu-select-placeholder {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
}

::v-deep .selStyle .ivu-select-selected-value {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
}

::v-deep .selStyle .ivu-select-item {
  font-size: 12px !important;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
}

::v-deep .ivu-input {
  border: none;
  color: #333333;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
}

::v-deep .lableStyle .el-date-editor {
  border: none;
}

::v-deep .el-date-editor > input {
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
}

::v-deep .el-date-editor > .el-range-separator {
  font-size: 12px;
  width: 20px;
  margin-top: 3px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN !important;
}

::v-deep .el-icon-circle-close:before {
  content: '\e79d';
  font-size: 14px;
}
//弹窗样式
.mainDown {
  .downHeader {
    padding: 30px 30px 0;
    display: flex;
    align-items: center;
    .downImg {
      width: 40px;
      height: 40px;
    }
    .headerRight {
      width: 100%;
      font-size: 16px;
      font-family: 'SourceHanSansCN-Medium, SourceHanSansCN';
      font-weight: 500;
      color: #333333;
      margin-left: 20px;
      .headerRightName {
        width: 320px;
        color: #333333;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .headerRightTime {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .downContent {
    width: 100%;
    min-height: 100px;
    margin: 0px 0 30px;
    max-height: 384px;
    overflow-y: auto;
    padding: 30px;
    .downRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .downBtnModal {
        height: 20px;
        font-size: 14px;
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
        font-weight: 400;
        color: #ff9900;
        cursor: pointer;
        //hover下划线
        &:hover {
          border-bottom: 1px solid #ff9900;
        }
      }
    }
    .noDataTxt {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
  }
  .downFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0 30px;
    .btn {
      width: 80px;
      height: 30px;
      border-radius: 15px;
      border: 1px solid #ff9900;
      font-size: 12px;
      font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
      font-weight: 400;
      color: #ff9900;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      margin: 0 10px;
    }
    .downBtn {
      background: #ff9900;
      color: #fff;
      //hover
      &:hover {
        background: #ff8800;
        border: 1px solid #ff8800;
      }
    }
  }
}
.downBtnTxtMain {
  position: relative;
  .texModal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
}
::v-deep .ivu-checkbox-default {
  display: flex;
  align-items: center;
  .downBtnTxt {
    width: 480px;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    margin-left: 5px;
  }
}
::v-deep .ivu-modal-body {
  padding: 0;
}
//弹窗多选框样式
::v-deep .ivu-checkbox-checked .ivu-checkbox-inner {
  background-color: rgba(0, 0, 0, 0);
  border-color: #ff9900;
}
::v-deep .ivu-checkbox-checked .ivu-checkbox-inner:after {
  border-color: #ff9900;
}
::v-deep .ivu-checkbox {
  margin-top: 3px;
}
</style>
