import feach from "@/utils/request";

//获取报告记录/交付中心列表
export const getDeliveryCenterList = params => {
  return feach.request({
    url: "/project/reportRecordPage",
    method: "get",
    params
  });
};
//获取交付类型列表
export const getDeliveryTypeList = params => {
  return feach.request({
    url: "/project/deliverTypeList",
    method: "get",
    params
  });
};
//确认报告
export const confirmReportData = data => {
  return feach.request({
    url: `/project/confirmation/${data.deliverId}`,
    method: "post",
    data
  });
};
//下载报告
export const downloadFirl = params => {
  return feach.request({
    url: "/download/zipDownload",
    method: "get",
    params
  });
};
//获取所有的报告列表
export const getDownloadFirlList = params => {
  return feach.request({
    url: `/project/uploadFileByDeliverId/${params.deliverId}`,
    method: "get",
    params
  });
};